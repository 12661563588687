/* Nunito Font */
@font-face {
  font-family: "Nunito Sans";
  src: url('assets/fonts/nunito-sans/NunitoSans-Light.ttf');
  font-weight: 400;
}
@font-face {
  font-family: "Nunito Sans";
  src: url('assets/fonts/nunito-sans/NunitoSans-Regular.ttf');
}
@font-face {
  font-family: "Nunito Sans";
  src: url('assets/fonts/nunito-sans/NunitoSans-Bold.ttf');
  font-weight: 600;
}
@font-face {
  font-family: "Nunito Sans";
  src: url('assets/fonts/nunito-sans/NunitoSans-ExtraBold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: "Nunito Sans";
  src: url('assets/fonts/nunito-sans/NunitoSans-Black.ttf');
  font-weight: 800;
}

.nunito-black {
  font-family: "Nunito Sans";
  font-weight: 800;
}
.nunito-extra-bold {
  font-family: "Nunito Sans";
  font-weight: 700;
}
.nunito-bold {
  font-family: "Nunito Sans";
  font-weight: 600;
}
.nunito-regular {
  font-family: "Nunito Sans";
}
.nunito-light {
  font-family: "Nunito Sans";
  font-weight: 400;
}

/* Variables */
:root {
  --purple: #7413dc;
  --teal: #088486;
  --red: #ed3f23;
  --pink: #ffb4e5;
  --green: #25b755;
  --navy: #003982;
  --blue: #006ddf;
  --yellow: #ffe627;
  --orange: #ff912a;
  --forest-green: #205b41;
  --gray-light: #f1f1f1;
  --gray-dark: #6e6e6e;
  --gray: #999;

  --max-container-width: 1440px;
  --container-horizontal-padding: 64px;
}

body {
  margin: 0;
  font-family: "Nunito Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#fullscreen {
  background-color: var(--purple);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
}

#fullscreen .logo {
  height: 40vmin;
  pointer-events: none;
}

/* Page layouts */
.cta-container {
  height: fit-content;
  width: fit-content;
  background-repeat:no-repeat;
  background-clip: content-box;
  background-size: cover;
  background-position: center;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.cta {
  width: fit-content;
  background-color: rgba(0,0,0,0.6);
  padding: 32px;
  margin: 0px 64px;
  color: white;
}

.fill-width {
  width: 100%;
}

.cta h1 {
  font-weight: 800;
  font-size: 44px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.cta h2 {
  margin-top: 0px;
  font-weight: 800;
}

.cta p {
  font-size: 18px;
}

.cta .button {
  font-size: 22px;
}

.buttons {
  display: flex;
  vertical-align: middle;
  gap: 12px;
}

.cards-container {
  max-width: var(--max-container-width);
  margin: 32px auto 32px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
}

.card {
  flex-basis: 350px;
  flex-grow: 1;

  --primary: var(--gray-light);
  --secondary: black;
  --invert-secondary: white;
  
  --button-background: var(--primary);
  --button-color: var(--secondary);
  --button-border: var(--secondary);
  --button-background-hover: var(--invert-secondary);
  --button-color-hover: var(--secondary);

  background-color: var(--primary);
  color: var(--secondary);
}

.yellow {
  --primary: var(--yellow);
  --secondary: black;
  --invert-secondary: white;
}

.purple {
  --primary: var(--purple);
  --secondary: white;
  --invert-secondary: black;
}

.blue {
  --primary: var(--blue);
  --secondary: white;
  --invert-secondary: black;
}

.forest-green {
  --primary: var(--forest-green);
  --secondary: white;
  --invert-secondary: black;
}

.navy {
  --primary: var(--navy);
  --secondary: white;
  --invert-secondary: black;
}

.red {
  --primary: var(--red);
  --secondary: white;
  --invert-secondary: black;
}

.pink {
  --primary: var(--pink);
  --secondary: black;
  --invert-secondary: white;
}

.buttons {
  --button-background: var(--primary);
  --button-color: var(--secondary);
  --button-border: var(--secondary);
  --button-background-hover: var(--secondary);
  --button-color-hover: var(--invert-secondary);

  flex-wrap: wrap;
}

.button {
  background-color: var(--button-background);
  color: var(--button-color);
  border: 1px solid var(--button-border);

  padding: 6px 12px;
  flex-grow: 1;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;

  /* align-self: center; */
  flex-basis: fit-content;
}

.button:hover {
  background-color: var(--button-background-hover);
  color: var(--button-color-hover);
}

.button.disabled {
  cursor: default;
  background-color: var(--gray-dark);
}

.button.disabled:hover {
  background-color: var(--gray-dark);
  color: var(--button-color);
  cursor: default;
}

.button.disabled:hover img {
  filter: invert(0%) !important;
}

.button.pink.disabled img {
  filter: invert(0%) !important;
}

.button.pink.disabled:hover img {
  filter: invert(0%) !important;
}



.no-border {
  --button-border: rgba(0,0,0,0);
}

.card-header {
  width: 100%;
  height: 300px;
  overflow:hidden;

  background-repeat:no-repeat;
  background-clip: content-box;
  background-size: cover;
  background-position: center;
}

.card-title {
  padding: 0 16px;
  margin: 12px 0 4px 0;
}

.card-body, .card-body ul {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 16px 12px 16px;
  white-space: pre-line;
}

.card-body ul {
  padding-bottom: 0;
}

.cards-container .card .card .card-title {
  padding: 8px 16px 0 16px;
}

.cards-container .card .card {
  flex-basis: unset;
}

.cards-container .card.no-title {
  padding-top: 12px;
}

.card.red .card-body {
  font-weight: 600;
}


@media only screen and (min-width: 768px) {
  .cta-container {
    min-height: 300px;
    height: calc(90vh - var(--topbar-height));
  }
}


@media only screen and (max-width: 1568px) {
  .cards-container {
    margin: 32px 64px;
  }
}

/* MOBILE */
@media only screen and (max-width: 768px) {
  .cta-container {
    justify-content: center;
  }

  .cta {
    margin: 16px;
  }

  .card {
    /* width: 100%; */
    flex-basis: 100%;
  }

  .cards-container {
    margin: 8px 8px;
  }
}

.policy-container {
  margin: 32px 64px;
}

@media only screen and (max-width: 768px) {
  .policy-container {
    margin: 16px 32px;
  }
}

.policy-container p {
  word-wrap: normal;
}

.time-container {
  max-width: var(--max-container-width);
  margin: 32px auto 0px auto;
}
.time-container .card {
  padding: 8px 0;
}