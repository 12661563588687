.sections-container {
    max-width: var(--max-container-width);
    margin: 2px auto 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2px;
}

.section-selector {
    background-color: var(--purple);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    padding: 32px 32px;
    flex-grow: 1;
    gap: 4px;
    cursor: pointer;
}

.section-selector:hover, .section-selector.active {
    background-color: white;
    color: black;
}

.section-selector:hover img, .section-selector.active img {
    filter: invert(100%);
}

@media only screen and (max-width: 900px) {
    .section-selector {
        flex-basis: 20%;
    }
  }


@media only screen and (max-width: 520px) {
    .section-selector {
        flex-basis: 30%;
    }
  }

.section-selector img {
    height: 24px;
    padding: 0;
    margin: 0;
    max-width: 120px;
}

.section-selector p {
    padding: 0;
    margin: 0;
}