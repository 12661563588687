.loader-container {
    min-height: calc(100vh - var(--topbar-height));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    text-align: center;
  }

.cards-column {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: fit-content;
}

.cards-column.small {
    min-width: 300px;
    flex-wrap: none;
    flex-direction: column;
}

.cards-column.large {
    flex-grow: 1;
    flex-direction: column;
}

.cards-column.large .card {
    width: 100%;
    position: relative;
    flex-basis: 0px;
}

.cards-column .card {
    flex-basis: unset;
    flex: none;
}

@media only screen and (max-width: 888px) {
    .cards-column {
        flex-direction: column;
        flex-basis: 100% !important;
    }

    .cards-column .card {
        flex: unset;
    }
}

.card-body {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.card-body input {
    transition: all .1s;
    font-size: 16px;
    font-family: 'Nunito Sans';
    border: 2px solid #e5e5e5;
    padding: 7px 8px;
}

.card-body input:focus {
    outline: none;
    border-bottom-color: var(--purple);
}

.card-body input.error {
    border-bottom-color: var(--red);
}

.card-body input:disabled {
    background-color: var(--gray-light);
}

.react-select__control {
    font-size: 16px !important;
    border: 2px solid #e5e5e5 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    color: black !important;
}

.react-select__control--is-focused {
    border-bottom-color: var(--purple) !important;
}

.react-select__menu {
    border-radius: 0 !important;
    color: black !important;
}

.add-team-container {
    flex-grow: 1;
}

.add-team-container .buttons {
    flex-direction: row;
}

.add-team-container .button {
    --button-color: white;
    --button-border: white;
    --button-background-hover: white;
    --button-color-hover: black;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    padding: 16px;
    flex-grow: 1;
    gap: 4px;
    cursor: pointer;
}

.card .button {
    --button-background: var(--primary);
    --button-color: var(--secondary);
    --button-border: var(--secondary);
    --button-background-hover: var(--secondary);
    --button-color-hover: var(--invert-secondary);
}

.card .button img {
    height: 24px;
    padding: 0;
    margin: 0;
    max-width: 100px;
}

.card .button.pink {
    --button-border: white;
}

.card .button.pink img {
    filter: invert(100%);
}

.card .button:hover img {
    filter: invert(100%);
}

.card .button.pink:hover img {
    filter: unset;
}

.card .delete {
    position: absolute;
    top: 0;
    right: 0;
    margin: 12px 16px 4px 0;
    cursor: pointer;
}

.card .delete img {
    height: 25px;
    filter: invert(100%);
}

.card .delete:hover img {
    filter: unset;
}

.card.pink .delete img {
    filter: unset;
}


.card.pink .delete:hover img {
    filter: invert(100%);
}

.team-props {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
}

.team-props .pack {
    flex-grow: 1;
}

.team-props .competitive-radio {
    display: flex;
    flex-direction: column;
    font-weight: 600;
}

.team-props .competitive-radio input:active {
    font-weight: 600;
}

.team-competitors-container {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    gap: 12px;
}

.team-competitor {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    /* flex-wrap: wrap; */
    gap: 3px;
}

.team-competitor input {
    flex-grow: 1;
}

.react-date-picker {
    border: 2px solid #e5e5e5;
    background-color: white;
    color: black;
}

.react-date-picker__wrapper {
    border: unset;
}

.react-date-picker__inputGroup__input {
    border: 0px !important;
    height: unset;
}

.card-body input:focus {
    outline: none;
    border-bottom-color: var(--purple);
}

.react-date-picker__inputGroup__input:invalid {
    background: unset;
}

.react-date-picker--open {
    border-bottom-color: var(--purple);
}

.required.invalid, .react-date-picker.invalid, .react-select__control.invalid {
    border-color: var(--red) !important;
}

.button.unclickable:hover {
    cursor: default;
    background-color: var(--button-background);
    color: var(--button-color);
}

.pricing {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid black;
}

.pricing-total {
    text-align: right;
}

.registration-tips {
    font-size: 14px;
    margin-bottom: 6px;
}

.esu-checkbox {
    font-size: 14px;
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.esu-checkbox input {
    cursor: pointer;
}