.cards-column {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-basis: min-content;
    height: fit-content;
}

.cards-column.small {
    flex-shrink: 1;
    flex-basis: 250px;
}

.cards-column.large {
    flex-grow: 1;
    flex-basis: min(50vw, 300px);
}

.cards-column .card {
    flex-basis: unset;
}

@media only screen and (max-width: 768px) {
    .cards-column {
        flex-direction: row;
        flex-shrink: 0;
        flex-grow: 1;
    }

    .cards-column {
        flex-wrap: wrap;
    }

    .cards-column.small .card {
        min-width: min-content;
    }

    .cards-column.large .card {
        width: 100%;
    }
  }


.modal-container {
    z-index: 2;
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-color: rgba(0,0,0,0.5);
    top: 0;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
}

.modal-exit {
    z-index: 4;
    position: absolute;
    top: 0;
    right: 0;
    padding: 12px;
    cursor: pointer;
}

.modal-exit:hover {
    filter: invert(100%);
}

.modal-background {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 3;
}

.modal-content {
    z-index: 4;
    max-height: 80vh;
    max-width: 80vw;
    object-fit: contain;
}