:root {
    --topbar-height: 128px;
  }

#topbar {
    position: fixed;
    top: 0;
    height: var(--topbar-height);
    width: 100vw;
    background-color: var(--purple);
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    box-shadow: 0px 2px 2px rgba(0,0,0,0.3);
    z-index: 1;
}

#topbar-placeholder {
    width: 100vw;
    height: var(--topbar-height);
}

#topbar > div {
    height: 100%;
    width: 100%;
}

.topbar-link {
    flex-grow: 0;
    font-size: min(20px, calc(8px + 1vw));
    text-decoration: none;
    color: white;
    transition: all .2s;
}

@media only screen and (max-width: 768px) {
    :root {
        --topbar-height: 96px;
    }

    #topbar {
      height: var(--topbar-height);
      justify-content: center;
    }
    #topbar-placeholder {
        height: var(--topbar-height);
    }
    .topbar-middle {
        order: unset;
        position: absolute;
    }
    .topbar-link {
        font-size: unset;
        padding: 1vh 0;
    }
  }


.topbar-link:hover {
    color: var(--yellow);
}

.topbar-img {
    height: 100%;
    transition: unset;
}

.topbar-img:hover {
    filter: invert(84%) sepia(87%) saturate(6867%) hue-rotate(330deg) brightness(103%) contrast(105%);
}

.topbar-left {
    order: 1;
}

.topbar-middle {
    order: 2;
}

.topbar-right {
    order: 3;
}