.results-container {
    max-width: var(--max-container-width);
    margin: 1px auto 32px auto;
    display: flex;
    flex-direction: column;
}

.ag-theme-alpine {
    --ag-foreground-color: black;
    --ag-background-color: white;
    --ag-header-foreground-color: white;
    --ag-header-background-color: var(--purple);
    --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
    --ag-header-column-resize-handle-color: white;

    --ag-font-size: min(2.5vw, 16px);
    --ag-font-family: 'Nunito Sans';
}

.ag-theme-alpine .ag-header-cell {
    font-size: 18px;
    font-weight: 800;
}

.ag-cell {
    text-align: center;
    word-break: keep-all;
}

.ag-header-cell-label {
    justify-content: center;
}

.ag-row .ag-cell {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .non-competitive {
    color: var(--purple);
  }

  .competitive-label {
    max-width: var(--max-container-width);
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    background-color: var(--gray-light);
    padding: 12px 0;
    margin: 8px auto;
  }


.event-card {
    flex-basis: unset;
    flex-grow: 1;
    padding: 24px 48px;
    text-align: center;
}

.event-card .card-body {
    align-items: center;
}

.event-card .card-body .button {
    padding: 6px 24px;
}

.section-cards {
    justify-content: center;
    margin: 8px auto;
    gap: 8px;
}

.section-cards .card:first-child {
    flex-basis: max-content;
    flex-grow: 0;
}

@media only screen and (max-width: 600px) {
    .section-cards .card:first-child {
        flex-basis: min-content;
    }   
}

.non-compete-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--gray-light);
    text-align: center;
    flex-grow: 4;
    font-weight: bold;
}